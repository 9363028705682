export const SIDEBAR_TAB_NAME = {
    INVOICING: 'invoicing',
    DASHBOARD: 'dashboard',
    PLANNING: 'planning',
    CALENDAR: 'calendar',
    TIMESHEET: 'timesheet',
    SETTINGS: 'settings',
    TASK: 'task',
    WORKSITE: 'worksite',
    BANK_ACCOUNT: 'bankaccount',
    CRM: 'crm',
} as const;

export type SidebarTabName = Enum<typeof SIDEBAR_TAB_NAME>;
