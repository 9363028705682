import { SidebarTabName } from '~/app/enums/SidebarTabName';

export const PAGE_VIEW_DATA_TYPE = {
    LIST: 'list',
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',
} as const;

export type PageViewDataType = Enum<typeof PAGE_VIEW_DATA_TYPE>;

export interface PageViewData {
    module: SidebarTabName;
    page: string;
    type: string;
    options?: Record<string, string>;
    modal?: string;
}

export type BaseTrackingServiceConstructor = { queryParams: URLSearchParams; pathSegments: string[]; moduleName: SidebarTabName };

export abstract class BaseTrackingService {
    getPageViewData(): PageViewData {
        return {
            module: this.getDefaultModuleName(),
            page: this.getDefaultPage(),
            type: this.getDefaultType(),
            options: this.getDefaultOptions(),
            modal: this.getDefaultModal(),
        };
    }

    protected abstract getDefaultModuleName(): SidebarTabName;

    protected abstract getDefaultPage(): string;

    protected abstract getDefaultType(): PageViewData['type'];

    protected getDefaultOptions(): Record<string, any> | undefined {
        return undefined;
    }

    protected getDefaultModal(): string | undefined {
        return undefined;
    }

    protected abstract getQueryParams(): URLSearchParams;

    protected getPageWithTab(basePage: string): string {
        if (!basePage) return '';

        const tab = this.getQueryParams().get('tab')?.trim();

        return tab ? `${basePage}_${tab}` : basePage;
    }
}
