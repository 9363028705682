import { BasePageTrackingService } from '../BasePageTrackingService';
import { PAGE_VIEW_DATA_TYPE, PageViewData } from '../BaseTrackingService';

export class SettingsTrackingService extends BasePageTrackingService {
    protected getDefaultPage(): string {
        return this.getNestedPageWithTab();
    }

    protected getDefaultType(): PageViewData['type'] {
        const path = this.pathSegments[this.pathSegments.length - 1];
        if (path === 'terms-of-service') {
            return PAGE_VIEW_DATA_TYPE.VIEW;
        }

        const listPaths = ['users', 'logs', 'invoices'];
        const isList = listPaths.includes(path);

        return isList ? PAGE_VIEW_DATA_TYPE.LIST : PAGE_VIEW_DATA_TYPE.EDIT;
    }
}
