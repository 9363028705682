import { BaseTrackingService, BaseTrackingServiceConstructor } from './BaseTrackingService';
import { InvoicingTrackingService } from './modules/InvoicingTrackingService';
import { PlanningTrackingService } from './modules/PlanningTrackingService';
import { SettingsTrackingService } from './modules/SettingsTrackingService';
import { TaskTrackingService } from './modules/TaskTrackingService';
import { DashboardTrackingService } from './modules/DashboardTrackingService';
import { WorksiteTrackingService } from './modules/WorksiteTrackingService';
import { CrmTrackingService } from './modules/CrmTrackingService';
import { BankAccountTrackingService } from './modules/BankAccountTrackingService';
import { SIDEBAR_TAB_NAME, SidebarTabName } from '~/app/enums/SidebarTabName';

export class TrackingServiceFactory {
    static create(path: string, queryParams: URLSearchParams): BaseTrackingService {
        const pathSegments = path.split('/').filter((segment) => !!segment);
        let moduleName: SidebarTabName = pathSegments[0] as any as SidebarTabName;
        const missingSegments = pathSegments.length === 0;
        if (missingSegments) {
            moduleName = SIDEBAR_TAB_NAME.DASHBOARD;
        }
        const params: BaseTrackingServiceConstructor = {
            moduleName,
            pathSegments,
            queryParams,
        };

        switch (moduleName) {
            case SIDEBAR_TAB_NAME.DASHBOARD:
                return new DashboardTrackingService(params);
            case SIDEBAR_TAB_NAME.INVOICING:
                return new InvoicingTrackingService(params);
            case SIDEBAR_TAB_NAME.PLANNING:
            case SIDEBAR_TAB_NAME.CALENDAR:
            case SIDEBAR_TAB_NAME.TIMESHEET:
                return new PlanningTrackingService(params);
            case SIDEBAR_TAB_NAME.SETTINGS:
                return new SettingsTrackingService(params);
            case SIDEBAR_TAB_NAME.TASK:
                return new TaskTrackingService(params);
            case SIDEBAR_TAB_NAME.WORKSITE:
                return new WorksiteTrackingService(params);
            case SIDEBAR_TAB_NAME.CRM:
                return new CrmTrackingService(params);
            case SIDEBAR_TAB_NAME.BANK_ACCOUNT:
                return new BankAccountTrackingService(params);
            default:
                throw new Error(`Module ${moduleName} has no tracking defined.`);
        }
    }
}
