import { validate as isValidUuid } from 'uuid';
import { BaseTrackingService, PAGE_VIEW_DATA_TYPE, PageViewData } from './BaseTrackingService';
import { SidebarTabName } from '~/app/enums/SidebarTabName';

export type BaseTrackingServiceConstructor = { queryParams: URLSearchParams; pathSegments: string[]; moduleName: SidebarTabName };

export abstract class BasePageTrackingService extends BaseTrackingService {
    protected pathSegments: string[];
    protected queryParams: URLSearchParams;
    protected moduleName: SidebarTabName;

    constructor({ pathSegments, queryParams, moduleName }: BaseTrackingServiceConstructor) {
        super();
        this.pathSegments = pathSegments;
        this.queryParams = queryParams;
        this.moduleName = moduleName;
    }

    protected getDefaultModuleName() {
        return this.moduleName;
    }

    protected getDefaultPage() {
        return this.getPageWithTab(this.moduleName);
    }

    protected getQueryParams(): URLSearchParams {
        return this.queryParams;
    }

    protected getPageSegments(): string[] {
        return this.pathSegments
            .slice(1)
            .filter((segment) => !isValidUuid(segment))
            .filter((segment) => segment !== PAGE_VIEW_DATA_TYPE.EDIT && segment !== PAGE_VIEW_DATA_TYPE.CREATE);
    }

    protected getNestedPageWithTab(): string {
        const basePage = this.getPageSegments().join('_');

        return this.getPageWithTab(basePage);
    }

    protected getDefaultType(): PageViewData['type'] {
        if (this.pathSegments.includes(PAGE_VIEW_DATA_TYPE.CREATE)) return PAGE_VIEW_DATA_TYPE.CREATE;
        if (this.pathSegments.includes(PAGE_VIEW_DATA_TYPE.EDIT)) return PAGE_VIEW_DATA_TYPE.EDIT;
        const containsUuid = !!this.pathSegments.find((segment) => isValidUuid(segment));

        return containsUuid ? PAGE_VIEW_DATA_TYPE.VIEW : PAGE_VIEW_DATA_TYPE.LIST;
    }

    protected getDefaultOptions(): Record<string, any> {
        const query: Record<string, any> = {};
        this.queryParams.forEach((value, key) => {
            query[key] = this.getQueryParamValue(value);
        });

        return { query };
    }

    protected getQueryParamValue(value: string) {
        const isJson = value.startsWith('[') || value.startsWith('{');
        if (isJson) {
            try {
                return JSON.parse(value);
            } catch {
                return value;
            }
        }

        const isDate = value.match(/^\d{4}-\d{2}-\d{2}/);
        if (isDate) {
            return {
                type: 'date',
                value,
            };
        }

        const numberValue = Number(value);
        const isNumber = !isNaN(numberValue);
        if (isNumber) {
            return numberValue;
        }

        const isBoolean = Boolean(value);
        if (isBoolean) {
            return value === 'true';
        }

        return value;
    }
}
