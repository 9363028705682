import { BasePageTrackingService } from '../BasePageTrackingService';

export class TaskTrackingService extends BasePageTrackingService {
    protected getDefaultPage(): string {
        const basePage = super.getDefaultPage();
        const view = this.queryParams.get('view');

        return view ? `${basePage}_${view}` : basePage;
    }
}
