import { useRoute } from 'vue-router';
import type { WildcardHandler } from 'mitt';
import { useListen, type ApplicationEvents } from './useEventBus';
import { useNuxtApp } from '#imports';
import { useModalsStore } from '~/stores/modalsStore';
import { useSetupStore } from '~/stores/setupStore';
import { BaseModalTrackingService } from '~/services/tracking/BaseModalTrackingService';

export function useMixPanelModalTracking() {
    const setupStore = useSetupStore();
    const modalStore = useModalsStore();
    const { $mixpanel } = useNuxtApp();
    const route = useRoute();

    const handleModalEvent = (type: string, payload: any): void => {
        const path = route.path;
        const queryParams = new URLSearchParams(route.query as any as Record<string, string>);
        const service = new BaseModalTrackingService(type, payload, path, queryParams);
        const pageViewData = service.getPageViewData();

        $mixpanel.track('page_view', pageViewData);
    };

    // Setup event listeners when setup is complete
    const init = () =>
        watchEffect(() => {
            if (!setupStore.completed) return;

            const wildcardHandler: WildcardHandler<ApplicationEvents> = (type, payload) => {
                if (!$mixpanel) return;
                const isOpenEvent = type && typeof type === 'string' && type.endsWith(':open');
                if (!isOpenEvent) {
                    return;
                }

                const potentialModalName = type.replace(/:open$/, '');
                const isCurrentModal = potentialModalName === modalStore.getLatestModal();

                if (!isCurrentModal) {
                    return;
                }

                handleModalEvent(potentialModalName, payload);
            };

            useListen('*', wildcardHandler);
        });

    return { init };
}
