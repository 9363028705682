import { useRoute } from 'vue-router';
import { useNuxtApp, watch, ref } from '#imports';
import { TrackingServiceFactory } from '~/services/tracking/TrackingServiceFactory';

export function useMixPanelPageView() {
    const { $mixpanel } = useNuxtApp();
    const route = useRoute();
    const lastTracked = ref<string>('');
    const trackingTimeout = ref<NodeJS.Timeout | null>(null);
    const retryAfterMs = 100;
    const destroy = () => {
        if (trackingTimeout.value) {
            clearTimeout(trackingTimeout.value);
        }
    };

    const track = () => {
        if (!$mixpanel) return;

        destroy();

        // Set a new timeout to wait for all query parameters to load.
        trackingTimeout.value = setTimeout(() => {
            const currentTracked = route.fullPath;
            const routeHasChanged = currentTracked && lastTracked.value !== currentTracked;
            if (!routeHasChanged) {
                return;
            }
            const routePath = route.path.replace(/\/$/, '');
            const routeQuery = new URLSearchParams(route.query as Record<string, string>);
            try {
                const trackingService = TrackingServiceFactory.create(routePath, routeQuery);
                const pageViewData = trackingService.getPageViewData();

                $mixpanel.track('page_view', pageViewData);
                lastTracked.value = currentTracked;
            } catch (error: unknown) {
                if (error instanceof Error) {
                    console.error(error.message);
                }
            }
        }, retryAfterMs);
    };

    const init = () => {
        track();
        watch(() => route.fullPath, track);
    };

    return { destroy, init };
}
