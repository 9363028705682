import { BasePageTrackingService } from '../BasePageTrackingService';
import { PAGE_VIEW_DATA_TYPE, PageViewData } from '../BaseTrackingService';

export class InvoicingTrackingService extends BasePageTrackingService {
    protected getDefaultType(): PageViewData['type'] {
        const isEdit = this.pathSegments[1] === 'settings' && this.pathSegments[2] === 'notifications' && this.pathSegments[3] === 'documents' && this.queryParams.has('tab');

        return isEdit ? PAGE_VIEW_DATA_TYPE.EDIT : super.getDefaultType();
    }

    protected getDefaultPage(): string {
        const [, secondSegment, thirdSegment] = this.pathSegments;
        const isImportOrExport = ['import', 'export'].includes(secondSegment) && thirdSegment === 'documents';
        if (isImportOrExport) {
            return `${secondSegment}_${thirdSegment}`;
        }

        return this.getNestedPageWithTab();
    }
}
