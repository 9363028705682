import { BaseTrackingService, PAGE_VIEW_DATA_TYPE, PageViewData } from './BaseTrackingService';
import { TrackingServiceFactory } from './TrackingServiceFactory';
import { SidebarTabName } from '~/app/enums/SidebarTabName';

export class BaseModalTrackingService extends BaseTrackingService {
    private eventName: string;
    private payload: any;
    private path: string;
    private queryParams: URLSearchParams;
    private pageViewData?: PageViewData;

    constructor(eventName: string, payload: any, path: string, queryParams: URLSearchParams) {
        super();
        this.eventName = eventName;
        this.payload = payload;
        this.path = path;
        this.queryParams = queryParams;
    }

    protected getDefaultModuleName(): SidebarTabName {
        const trackingService = TrackingServiceFactory.create(this.path, this.queryParams);
        this.pageViewData = trackingService.getPageViewData();

        return this.pageViewData.module;
    }

    protected getQueryParams(): URLSearchParams {
        return this.queryParams;
    }

    protected getDefaultPage(): string {
        const isClosureReport = this.eventName.includes('closure-report');
        if (isClosureReport) {
            return 'closure-report';
        }

        const parts = this.getEventParts();
        const [moduleName, pageName] = parts;

        if (moduleName !== 'contact') {
            return pageName;
        }

        return this.getPageWithTab(pageName);
    }

    protected getDefaultType(): PageViewData['type'] {
        if (this.eventName.includes(':form-modal')) {
            return this.payload?.model ? PAGE_VIEW_DATA_TYPE.EDIT : PAGE_VIEW_DATA_TYPE.CREATE;
        }

        if (this.eventName.includes(':modal')) {
            return PAGE_VIEW_DATA_TYPE.VIEW;
        }

        // For other events, use the last part before :open as the type
        const parts = this.getEventParts();
        const lastPartBeforeOpen = parts[parts.length - 1];

        return lastPartBeforeOpen;
    }

    protected getDefaultModal(): string | undefined {
        return this.eventName;
    }

    protected getDefaultOptions(): Record<string, any> | undefined {
        return { current_page: this.pageViewData };
    }

    protected getEventParts(): string[] {
        return this.eventName.split(':');
    }
}
